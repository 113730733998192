.horizontal-border {
	border-bottom: 1px solid #eeeeee;
}

.vertical-border {
	border-right: 1px solid #eeeeee;
}

#passions {
	margin-top: 100px;
	margin-bottom: 100px;
}

#passions img {
	width: 270px;
	height: 270px;
	margin: 30px;
	padding: 5px;
	border: 5px solid #ffffff;
}

#passions img:hover {
	cursor: pointer;
	border-style: dotted;
	border-width: 5px;
}

#passions img.francesca:hover {
	border-color: #F9A2C5;
}

#passions img.luca:hover {
	border-color: #202C6A;
}

#passions img.noi:hover {
	border-top-color: #F9A2C5;
	border-left-color: #F9A2C5;
	border-right-color: #202C6A;
	border-bottom-color: #202C6A;
}

@media (max-width: 1200px) {
	#passions img {
		width: 230px;
		height: 230px;
		margin: 20px;
	}
}

@media (max-width: 992px) {
	#passions img {
		width: 180px;
		height: 180px;
		margin: 10px;
	}

}

@media (max-width: 768px) {
	#passions img {
		width: 130px;
		height: 130px;
		margin: 10px;
	}

	.horizontal-border {
		border-bottom: none;
	}

	.vertical-border {
		border-right: none;
	}
}

@media (max-width: 576px) {
	#passions img {
		width: 270px;
		height: 270px;
		margin: 30px;
	}
}