.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	font-size: 1em;
}

.modal-main {
	position: fixed;
	background: white;
	width: 70%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 14px;
	padding: 70px 100px;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.close-button {
	color: #fff;
	background-color: #dddddd;
	border-color: #cccccc;
}

.close-button:hover {
	color: #bbbbbb;
	background-color: #ffffff;
	border-color: #dddddd;
}

.save-button {
	color: #fff;
	background-color: #a7464f;
	border-color: #882832;
	margin-left: 25px;
}

.save-button:hover,
.save-button:active,
.save-button:focus {
	color: #fff;
	background-color: #882832;
	border-color: #882832;
}

.save-button:disabled,
.save-button:disabled:hover {
	color: #fff;
	background-color: #dddddd;
	border-color: #cccccc;
}