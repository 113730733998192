@font-face {
	font-family: 'Pompiere';
	src:
		url('../fonts/pompiere-regular-webfont.woff') format('woff'),
		url('../fonts/pompiere-regular-webfont.woff2') format('woff2'),
		url('../fonts/pompiere-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src:
		url('../fonts/lato-thin-webfont.woff') format('woff'),
		url('../fonts/lato-thin-webfont.woff2') format('woff2'),
		url('../fonts/lato-thin-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.main {
	font-family: 'Pompiere', Arial, Helvetica, sans-serif;
}

.main p,
.main input,
.main label {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.main h1,
.main a {
	color: #000;
}

.main-container {
	max-width: 70em;
}

.pompiere {
	font-family: 'Pompiere', Arial, Helvetica, sans-serif;
}

.lato {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.welcome {
	-webkit-animation: fadein 2s;
	/* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s;
	/* Firefox < 16 */
	-ms-animation: fadein 2s;
	/* Internet Explorer */
	-o-animation: fadein 2s;
	/* Opera < 12.1 */
	animation: fadein 2s;
	background: url(../imgs/welcome-bg.png) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	width: 100vw;
	height: 100vh;
}

.about-us {
	width: 100vw;
	height: 100vh;
}

/* Animations */

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.error {
	margin-top: 35px;
	font-size: 1.1rem;
}

.red {
	color: #AA1C0D
}