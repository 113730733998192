#lettera .align-right {
	text-align: right;
}

#lettera .more p {
	font-size: 1.5em;
}

#lettera p.moreMore {
	font-size: 3em;
	color: #a7464f;
	margin: 50px;
}

#lettera .align-center {
	text-align: center;
}

#lettera .love-link {
	color: #a7464f;
}