#san-valentino-head {
	background-color: #a7464f;
	margin: 0;
}

#san-valentino-head .love-img {
	width: 150px;
	height: auto;
	margin-bottom: 30px;
}

#san-valentino-head .love-title {
	color: #ffffff;
	font-size: 5em;
}

.home-link {
	color: #ffffff;
}

.home-link:hover {
	color: #ffffff;
	text-decoration: underline;
}

/* per dispositivi piccoli tipo smartphone */
#fotografie img {
	max-width: 100%;
	max-height: 100%;
	display: inline-block;
	width: 100%;
	height: 100%;
}

/* Per dispositivi medie tablet */
@media (min-width: 420px) {
	#fotografie img {
		max-width: 48%;
		max-height: 48%;
	}
}

/* per dispositivi con gli scermi grandi PC o pc portatile */
@media (min-width: 760px) {
	#fotografie img {
		max-width: 24%;
		max-height: 24%;
	}
}

.foto-container {
	width: 100%;
	height: 100%;
	text-align: center;
}

#fotografie {
	background-color: #a7464f;
}