#francesca {
	background-color: #FFFCF5;
	padding: 50px;
	height: 100%;
	color: #303030;
}

.circle-div {
	border: 8px solid #F9A2C5;
	border-radius: 50%;
	width: 300px;
	height: 300px;
}

.pink-left-border {
	border-left: 3px solid #F9A2C5;
}

.pink-right-border {
	border-right: 3px solid #F9A2C5;
}

.pink {
	color: #F9A2C5;
}

.name-content {
	height: 284px;
}

@media (max-width: 48em) {
	#francesca {
		padding: 20px;
	}

	.circle-div {
		width: 220px;
		height: 220px;
		border-width: 6px;
	}

	.circle-div h1 {
		font-size: 2.2rem;
	}

	.circle-div h5 {
		font-size: 1.2rem;
	}

	.name-content {
		height: 208px;
	}

	#francesca div {
		display: block;
	}

	.pink-right-border,
	.pink-left-border {
		border: none
	}

	.pink-right-border>div,
	.pink-left-border>div {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	#curriculum .left {
		-ms-flex-align: start !important;
		align-items: flex-start !important;
	}

	#curriculum .left p,
	#curriculum .left ul {
		text-align: left !important;
	}

	#curriculum .left ul {
		padding-left: 0;
	}


}

#francesca ul {
	list-style-type: none;
}

#francesca a {
	color: #303030;
}

#francesca a:hover {
	color: #F9A2C5;
}