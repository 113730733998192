.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.message {
	font-size: 1.5em;
}

.author-date {
	font-size: 12px;
	padding-top: 5px;
}

.love-items {
	display: block;
}

.love-div {
	margin: 20px 50px;
}