.cover {
    padding: 0 1.5rem;
}

.cover-heading {
    font-size: 4rem;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.cover p.lead {
    font-size: 2.7rem;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.animate-pop-in {
    -webkit-animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
    animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
    opacity: 0;
}

@-webkit-keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-4rem) scale(.8);
        transform: translateY(-4rem) scale(.8);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-4rem) scale(.8);
        transform: translateY(-4rem) scale(.8);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
.us img {
	width: 300px;
	height: 300px;
}

.us .row {
	margin-top: 100px;
	margin-bottom: 100px;
}

.border-separation {
	border-right: 1px solid #ddd;
}

@media (max-width: 992px) {
	.border-separation {
		border-right: none;
	}
}
.masthead {
	margin-bottom: 2rem;
}

.masthead-brand {
	margin-bottom: 0;
	font-size: 2.5rem;
}

@media (min-width: 48em) {
	.masthead-brand {
		float: left;
	}

	.nav-masthead {
		float: right;
	}
}

.nav-masthead .nav-link {
	font-size: 1.9rem;
	color: #000;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
	color: #17a2b8;
}

.nav-masthead .nav-link+.nav-link {
	margin-left: 1rem;
}

.nav-masthead .active {
	color: red;
	border-bottom-color: red;
}
.mastfoot {
	padding: 0;
}
@font-face {
	font-family: 'Pompiere';
	src:
		url(/static/media/pompiere-regular-webfont.266b8706.woff) format('woff'),
		url(/static/media/pompiere-regular-webfont.56a03cdc.woff2) format('woff2'),
		url(/static/media/pompiere-regular-webfont.a1f363a1.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src:
		url(/static/media/lato-thin-webfont.945ac782.woff) format('woff'),
		url(/static/media/lato-thin-webfont.e6467b8a.woff2) format('woff2'),
		url(/static/media/lato-thin-webfont.ca8d575c.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

.main {
	font-family: 'Pompiere', Arial, Helvetica, sans-serif;
}

.main p,
.main input,
.main label {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.main h1,
.main a {
	color: #000;
}

.main-container {
	max-width: 70em;
}

.pompiere {
	font-family: 'Pompiere', Arial, Helvetica, sans-serif;
}

.lato {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.welcome {
	-webkit-animation: fadein 2s;
	/* Safari, Chrome and Opera > 12.1 */
	/* Firefox < 16 */
	/* Internet Explorer */
	/* Opera < 12.1 */
	animation: fadein 2s;
	background: url(/static/media/welcome-bg.cd29fe95.png) no-repeat center center fixed;
	background-size: cover;
	width: 100vw;
	height: 100vh;
}

.about-us {
	width: 100vw;
	height: 100vh;
}

/* Animations */

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

/* Internet Explorer */

/* Opera < 12.1 */

.error {
	margin-top: 35px;
	font-size: 1.1rem;
}

.red {
	color: #AA1C0D
}
@font-face {
	font-family: 'Glacial Indifference Bold';
	src:
		url(/static/media/glacialindifference-bold-webfont.aabfe681.woff) format('woff'),
		url(/static/media/glacialindifference-bold-webfont.08ae3833.woff2) format('woff2'),
		url(/static/media/glacialindifference-bold-webfont.f0c7d6f7.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Glacial Indifference Italic';
	src:
		url(/static/media/glacialindifference-italic-webfont.cbd8907f.woff) format('woff'),
		url(/static/media/glacialindifference-italic-webfont.84192742.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Glacial Indifference';
	src:
		url(/static/media/glacialindifference-regular-webfont.41afae3e.woff) format('woff'),
		url(/static/media/glacialindifference-regular-webfont.4d3c6e99.woff2) format('woff2'),
		url(/static/media/glacialindifference-regular-webfont.4d563c1a.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Kollektif Bold';
	src:
		url(/static/media/kollektif-bold-webfont.5329816d.woff) format('woff'),
		url(/static/media/kollektif-bold-webfont.e2c17319.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Kollektif';
	src:
		url(/static/media/kollektif-webfont.0fc24dd6.woff) format('woff'),
		url(/static/media/kollektif-webfont.2f79b938.woff2) format('woff2'),
		url(/static/media/kollektif-webfont.584ab6c1.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Light';
	src:
		url(/static/media/montserrat-light-webfont.232d4449.woff) format('woff'),
		url(/static/media/montserrat-light-webfont.1dd08ade.woff2) format('woff2'),
		url(/static/media/montserrat-light-webfont.9d3399e5.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Playlist Script';
	src:
		url(/static/media/playlist_script-webfont.a1d6056e.woff) format('woff'),
		url(/static/media/playlist_script-webfont.c3127bee.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'brusherregular';
	src: url(/static/media/brusher-webfont.c04359db.woff2) format('woff2'),
		url(/static/media/brusher-webfont.295e9cd9.woff) format('woff');
	font-weight: normal;
	font-style: normal;

}

.playlist-script {
	font-family: 'Playlist Script', 'Times New Roman', Times, serif;
}


.montserrat-light {
	font-family: 'Montserrat Light', Arial, Helvetica, sans-serif;
}

.kollektif {
	font-family: 'Kollektif', Arial, Helvetica, sans-serif;
}

.kollektif-bold {
	font-family: 'Kollektif Bold', Arial, Helvetica, sans-serif;
}

.glacial-indifference-bold {
	font-family: 'Glacial Indifference Bold', Arial, Helvetica, sans-serif;
}

.glacial-indifference-italic {
	font-family: 'Glacial Indifference Italic', Arial, Helvetica, sans-serif;
}

.glacial-indifference {
	font-family: 'Glacial Indifference', Arial, Helvetica, sans-serif;
}

.brusher {
	font-family: 'brusherregular', 'Times New Roman', Times, serif;
}

.kite-one {
	font-family: 'Kite One',
		sans-serif;
}
#francesca {
	background-color: #FFFCF5;
	padding: 50px;
	height: 100%;
	color: #303030;
}

.circle-div {
	border: 8px solid #F9A2C5;
	border-radius: 50%;
	width: 300px;
	height: 300px;
}

.pink-left-border {
	border-left: 3px solid #F9A2C5;
}

.pink-right-border {
	border-right: 3px solid #F9A2C5;
}

.pink {
	color: #F9A2C5;
}

.name-content {
	height: 284px;
}

@media (max-width: 48em) {
	#francesca {
		padding: 20px;
	}

	.circle-div {
		width: 220px;
		height: 220px;
		border-width: 6px;
	}

	.circle-div h1 {
		font-size: 2.2rem;
	}

	.circle-div h5 {
		font-size: 1.2rem;
	}

	.name-content {
		height: 208px;
	}

	#francesca div {
		display: block;
	}

	.pink-right-border,
	.pink-left-border {
		border: none
	}

	.pink-right-border>div,
	.pink-left-border>div {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}

	#curriculum .left {
		-webkit-align-items: flex-start !important;
		        align-items: flex-start !important;
	}

	#curriculum .left p,
	#curriculum .left ul {
		text-align: left !important;
	}

	#curriculum .left ul {
		padding-left: 0;
	}


}

#francesca ul {
	list-style-type: none;
}

#francesca a {
	color: #303030;
}

#francesca a:hover {
	color: #F9A2C5;
}
#luca {
	padding: 50px;
	height: 100%;
	color: #0000cd;
}

#luca .black-right-border h6 {
	margin-bottom: 1rem;
}

#luca h6.title {
	margin-bottom: 2rem;
	margin-top: 3rem;
}

.black-left-border {
	border-left: 3px solid #0000cd;
}

.black-right-border {
	border-right: 3px solid #0000cd;
}

.name-block {
	padding: 5rem 2rem;
	border-bottom: 6px solid #0000cd;
}

.name-block p {
	margin-top: 2rem;
}

.profile-block,
.skills-block {
	padding: 2rem 2rem 5rem 2rem;
	border-bottom: 6px solid #0000cd;
}

.skills-block ul {
	padding-left: 20px;
}

.contacts-block {
	padding: 2rem 2rem 5rem 2rem;
}

.contacts-block ul {
	padding-left: 0;
	list-style: none;
}

.contacts-block a {
	color: #0000cd;
}

.contacts-block a:hover {
	text-decoration: underline;
}

#luca .black-left-border p.kollektif {
	margin-bottom: .5rem;
}

#luca .black-left-border p.montserrat-light {
	margin-bottom: 1.5rem;
}

@media (max-width: 570px) {

	.black-left-border,
	.black-right-border,
	.name-block,
	.profile-block,
	.skills-block {
		border: none;
	}
}
.horizontal-border {
	border-bottom: 1px solid #eeeeee;
}

.vertical-border {
	border-right: 1px solid #eeeeee;
}

#passions {
	margin-top: 100px;
	margin-bottom: 100px;
}

#passions img {
	width: 270px;
	height: 270px;
	margin: 30px;
	padding: 5px;
	border: 5px solid #ffffff;
}

#passions img:hover {
	cursor: pointer;
	border-style: dotted;
	border-width: 5px;
}

#passions img.francesca:hover {
	border-color: #F9A2C5;
}

#passions img.luca:hover {
	border-color: #202C6A;
}

#passions img.noi:hover {
	border-top-color: #F9A2C5;
	border-left-color: #F9A2C5;
	border-right-color: #202C6A;
	border-bottom-color: #202C6A;
}

@media (max-width: 1200px) {
	#passions img {
		width: 230px;
		height: 230px;
		margin: 20px;
	}
}

@media (max-width: 992px) {
	#passions img {
		width: 180px;
		height: 180px;
		margin: 10px;
	}

}

@media (max-width: 768px) {
	#passions img {
		width: 130px;
		height: 130px;
		margin: 10px;
	}

	.horizontal-border {
		border-bottom: none;
	}

	.vertical-border {
		border-right: none;
	}
}

@media (max-width: 576px) {
	#passions img {
		width: 270px;
		height: 270px;
		margin: 30px;
	}
}
#login {
	margin-top: 15%;
}

.card-signin {
	border: 1px solid;
	border-color: #dadce0;
	border-radius: 8px;
}

.card-signin .card-title {
	margin-bottom: 2rem;
}

.card-signin .card-body {
	padding: 2rem;
	margin-bottom: 15px;
	margin-top: 15px;
}

.form-signin {
	width: 100%;
	text-align: center;
}

.form-signin .btn {
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	background-color: #a7464f;
	border-color: #a7464f;
	margin: 0 auto;
}

#login .form-signin .btn:hover,
#login .form-signin .btn:active {
	background-color: #7e2c34;
	border-color: #7e2c34;
}

.form-label-group {
	margin-bottom: 3rem;
	border-radius: 4px;
	border: 1px solid #dadce0;
	padding: 2px;
}

.form-label-group input {
	border-radius: 4px;
	border: none;
	text-align: center;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: 16px;
	padding-left: 16px;
	height: auto;
	background-color: rgba(167, 70, 79, 0.185);
}

.login-tiitle {
	text-align: center;
	color: #a7464f;
}

.back {
	text-align: center;
	margin-top: 50px;
}

.back a {
	color: #a7464f;
	font-size: 1.5em;
}
#san-valentino-head {
	background-color: #a7464f;
	margin: 0;
}

#san-valentino-head .love-img {
	width: 150px;
	height: auto;
	margin-bottom: 30px;
}

#san-valentino-head .love-title {
	color: #ffffff;
	font-size: 5em;
}

.home-link {
	color: #ffffff;
}

.home-link:hover {
	color: #ffffff;
	text-decoration: underline;
}

/* per dispositivi piccoli tipo smartphone */
#fotografie img {
	max-width: 100%;
	max-height: 100%;
	display: inline-block;
	width: 100%;
	height: 100%;
}

/* Per dispositivi medie tablet */
@media (min-width: 420px) {
	#fotografie img {
		max-width: 48%;
		max-height: 48%;
	}
}

/* per dispositivi con gli scermi grandi PC o pc portatile */
@media (min-width: 760px) {
	#fotografie img {
		max-width: 24%;
		max-height: 24%;
	}
}

.foto-container {
	width: 100%;
	height: 100%;
	text-align: center;
}

#fotografie {
	background-color: #a7464f;
}
#lettera .align-right {
	text-align: right;
}

#lettera .more p {
	font-size: 1.5em;
}

#lettera p.moreMore {
	font-size: 3em;
	color: #a7464f;
	margin: 50px;
}

#lettera .align-center {
	text-align: center;
}

#lettera .love-link {
	color: #a7464f;
}
.tiamo-title {
	text-align: center;
	color: #a7464f;
}

.tiamo-button {
	color: #a7464f;
	margin-left: 200px;
	position: relative;
}

.tiamo-button::after {
	content: "+";
	font-size: 3em;
	position: absolute;
	right: 21px;
	color: #ffffff;
	top: -2px;
}

#ti-amo-perche {
	min-height: 700px;
}

#message-area {
	margin: 40px 0;
	padding: 30px;
	font-size: 1.1em;
	width: 80%;
	outline: none;
	resize: none;
	overflow: auto;
	border: 2px dotted #a7464f;
}

.input-hidden {
	position: absolute;
	left: -9999px;
}

input[type=radio]:checked+label>img {
	border: 3px solid #a7464f;
	padding: 5px;
	/* box-shadow: 0 0 1px 1px #a7464f; */
}

input[type=radio]+label>img {
	width: 100px;
	height: 100px;
	border-radius: 100px;
	margin: 30px 20px 0 20px;
	cursor: pointer;
}
.align-right {
	text-align: right;
}

.align-left {
	text-align: left;
}

.message {
	font-size: 1.5em;
}

.author-date {
	font-size: 12px;
	padding-top: 5px;
}

.love-items {
	display: block;
}

.love-div {
	margin: 20px 50px;
}
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	font-size: 1em;
}

.modal-main {
	position: fixed;
	background: white;
	width: 70%;
	height: auto;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	font-size: 14px;
	padding: 70px 100px;
}

.display-block {
	display: block;
}

.display-none {
	display: none;
}

.close-button {
	color: #fff;
	background-color: #dddddd;
	border-color: #cccccc;
}

.close-button:hover {
	color: #bbbbbb;
	background-color: #ffffff;
	border-color: #dddddd;
}

.save-button {
	color: #fff;
	background-color: #a7464f;
	border-color: #882832;
	margin-left: 25px;
}

.save-button:hover,
.save-button:active,
.save-button:focus {
	color: #fff;
	background-color: #882832;
	border-color: #882832;
}

.save-button:disabled,
.save-button:disabled:hover {
	color: #fff;
	background-color: #dddddd;
	border-color: #cccccc;
}
