.tiamo-title {
	text-align: center;
	color: #a7464f;
}

.tiamo-button {
	color: #a7464f;
	margin-left: 200px;
	position: relative;
}

.tiamo-button::after {
	content: "+";
	font-size: 3em;
	position: absolute;
	right: 21px;
	color: #ffffff;
	top: -2px;
}

#ti-amo-perche {
	min-height: 700px;
}

#message-area {
	margin: 40px 0;
	padding: 30px;
	font-size: 1.1em;
	width: 80%;
	outline: none;
	resize: none;
	overflow: auto;
	border: 2px dotted #a7464f;
}

.input-hidden {
	position: absolute;
	left: -9999px;
}

input[type=radio]:checked+label>img {
	border: 3px solid #a7464f;
	padding: 5px;
	/* box-shadow: 0 0 1px 1px #a7464f; */
}

input[type=radio]+label>img {
	width: 100px;
	height: 100px;
	-moz-border-radius: 100px;
	-webkit-border-radius: 100px;
	border-radius: 100px;
	margin: 30px 20px 0 20px;
	cursor: pointer;
}