#login {
	margin-top: 15%;
}

.card-signin {
	border: 1px solid;
	border-color: #dadce0;
	border-radius: 8px;
}

.card-signin .card-title {
	margin-bottom: 2rem;
}

.card-signin .card-body {
	padding: 2rem;
	margin-bottom: 15px;
	margin-top: 15px;
}

.form-signin {
	width: 100%;
	text-align: center;
}

.form-signin .btn {
	transition: all 0.2s;
	background-color: #a7464f;
	border-color: #a7464f;
	margin: 0 auto;
}

#login .form-signin .btn:hover,
#login .form-signin .btn:active {
	background-color: #7e2c34;
	border-color: #7e2c34;
}

.form-label-group {
	margin-bottom: 3rem;
	border-radius: 4px;
	border: 1px solid #dadce0;
	padding: 2px;
}

.form-label-group input {
	border-radius: 4px;
	border: none;
	text-align: center;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-right: 16px;
	padding-left: 16px;
	height: auto;
	background-color: rgba(167, 70, 79, 0.185);
}

.login-tiitle {
	text-align: center;
	color: #a7464f;
}

.back {
	text-align: center;
	margin-top: 50px;
}

.back a {
	color: #a7464f;
	font-size: 1.5em;
}