.us img {
	width: 300px;
	height: 300px;
}

.us .row {
	margin-top: 100px;
	margin-bottom: 100px;
}

.border-separation {
	border-right: 1px solid #ddd;
}

@media (max-width: 992px) {
	.border-separation {
		border-right: none;
	}
}