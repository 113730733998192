.masthead {
	margin-bottom: 2rem;
}

.masthead-brand {
	margin-bottom: 0;
	font-size: 2.5rem;
}

@media (min-width: 48em) {
	.masthead-brand {
		float: left;
	}

	.nav-masthead {
		float: right;
	}
}

.nav-masthead .nav-link {
	font-size: 1.9rem;
	color: #000;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
	color: #17a2b8;
}

.nav-masthead .nav-link+.nav-link {
	margin-left: 1rem;
}

.nav-masthead .active {
	color: red;
	border-bottom-color: red;
}