.cover {
    padding: 0 1.5rem;
}

.cover-heading {
    font-size: 4rem;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

.cover p.lead {
    font-size: 2.7rem;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

.animate-pop-in {
    -webkit-animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
    animation: pop-in .6s cubic-bezier(0, 0.9, 0.3, 1.2) forwards;
    opacity: 0;
}

@-webkit-keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-4rem) scale(.8);
        transform: translateY(-4rem) scale(.8);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes pop-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-4rem) scale(.8);
        transform: translateY(-4rem) scale(.8);
    }

    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}