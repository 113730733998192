@font-face {
	font-family: 'Glacial Indifference Bold';
	src:
		url('../fonts/glacialindifference-bold-webfont.woff') format('woff'),
		url('../fonts/glacialindifference-bold-webfont.woff2') format('woff2'),
		url('../fonts/glacialindifference-bold-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Glacial Indifference Italic';
	src:
		url('../fonts/glacialindifference-italic-webfont.woff') format('woff'),
		url('../fonts/glacialindifference-italic-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Glacial Indifference';
	src:
		url('../fonts/glacialindifference-regular-webfont.woff') format('woff'),
		url('../fonts/glacialindifference-regular-webfont.woff2') format('woff2'),
		url('../fonts/glacialindifference-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Kollektif Bold';
	src:
		url('../fonts/kollektif-bold-webfont.woff') format('woff'),
		url('../fonts/kollektif-bold-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Kollektif';
	src:
		url('../fonts/kollektif-webfont.woff') format('woff'),
		url('../fonts/kollektif-webfont.woff2') format('woff2'),
		url('../fonts/kollektif-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat Light';
	src:
		url('../fonts/montserrat-light-webfont.woff') format('woff'),
		url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
		url('../fonts/montserrat-light-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Playlist Script';
	src:
		url('../fonts/playlist_script-webfont.woff') format('woff'),
		url('../fonts/playlist_script-webfont.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'brusherregular';
	src: url('../fonts/brusher-webfont.woff2') format('woff2'),
		url('../fonts/brusher-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

.playlist-script {
	font-family: 'Playlist Script', 'Times New Roman', Times, serif;
}


.montserrat-light {
	font-family: 'Montserrat Light', Arial, Helvetica, sans-serif;
}

.kollektif {
	font-family: 'Kollektif', Arial, Helvetica, sans-serif;
}

.kollektif-bold {
	font-family: 'Kollektif Bold', Arial, Helvetica, sans-serif;
}

.glacial-indifference-bold {
	font-family: 'Glacial Indifference Bold', Arial, Helvetica, sans-serif;
}

.glacial-indifference-italic {
	font-family: 'Glacial Indifference Italic', Arial, Helvetica, sans-serif;
}

.glacial-indifference {
	font-family: 'Glacial Indifference', Arial, Helvetica, sans-serif;
}

.brusher {
	font-family: 'brusherregular', 'Times New Roman', Times, serif;
}

.kite-one {
	font-family: 'Kite One',
		sans-serif;
}