#luca {
	padding: 50px;
	height: 100%;
	color: #0000cd;
}

#luca .black-right-border h6 {
	margin-bottom: 1rem;
}

#luca h6.title {
	margin-bottom: 2rem;
	margin-top: 3rem;
}

.black-left-border {
	border-left: 3px solid #0000cd;
}

.black-right-border {
	border-right: 3px solid #0000cd;
}

.name-block {
	padding: 5rem 2rem;
	border-bottom: 6px solid #0000cd;
}

.name-block p {
	margin-top: 2rem;
}

.profile-block,
.skills-block {
	padding: 2rem 2rem 5rem 2rem;
	border-bottom: 6px solid #0000cd;
}

.skills-block ul {
	padding-left: 20px;
}

.contacts-block {
	padding: 2rem 2rem 5rem 2rem;
}

.contacts-block ul {
	padding-left: 0;
	list-style: none;
}

.contacts-block a {
	color: #0000cd;
}

.contacts-block a:hover {
	text-decoration: underline;
}

#luca .black-left-border p.kollektif {
	margin-bottom: .5rem;
}

#luca .black-left-border p.montserrat-light {
	margin-bottom: 1.5rem;
}

@media (max-width: 570px) {

	.black-left-border,
	.black-right-border,
	.name-block,
	.profile-block,
	.skills-block {
		border: none;
	}
}